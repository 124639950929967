import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../services/api";

export const fetchLeadData = createAsyncThunk(
  "fetchLeadData",
  async ({ 
    page = 1, 
    limit = 10, 
    search = '', 
    sortField = 'createdDate', 
    sortOrder = -1,
    leadName = '',
    leadEmail = '',
    leadPhoneNumber = '',
    price = '',
    address = '',
    county = '',
    marketFee = '',
    leadStatus = '',
    assignedTo='',
    campaign = ''
  }) => {
    const queryParams = new URLSearchParams({
      page,
      limit,
      search,
      sortField,
      sortOrder,
      ...(leadName && { leadName }),
      ...(leadEmail && { leadEmail }),
      ...(leadPhoneNumber && { leadPhoneNumber }),
      ...(price && { price }),
      ...(address && { address }),
      ...(county && { county }),
      ...(marketFee && { marketFee }),
      ...(leadStatus && { leadStatus }),
      ...(assignedTo && { assignedTo }),
      ...(campaign && {campaign})
    }).toString();
    
    const response = await getApi(`api/lead/?${queryParams}`);
    return response.data;
  }
);

const leadSlice = createSlice({
  name: "leadData",
  initialState: {
    data: [],
    pagination: {
      total: 0,
      page: 1,
      limit: 10,
      totalPages: 0
    },
    isLoading: false,
    error: "",
    search: "",
    sortField: "createdDate",
    sortOrder: -1,
    filters: {
      leadName: "",
      leadEmail: "",
      leadPhoneNumber: "",
      price: "",
      address: "",
      county: "",
      marketFee: "",
      leadStatus: "",
      assignedTo:"",
      campaign:""
    }
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSort: (state, action) => {
      state.sortField = action.payload.field;
      state.sortOrder = action.payload.order;
    },
    setPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    setLimit: (state, action) => {
      state.pagination.limit = action.payload;
    },
    setFilter: (state, action) => {
      const { field, value } = action.payload;
      state.filters[field] = value;
    },
    resetFilters: (state) => {
      state.filters = {
        leadName: "",
        leadEmail: "",
        leadPhoneNumber: "",
        price: "",
        address: "",
        county: "",
        marketFee: "",
        leadStatus: "" ,
        assignedTo:"",
        campaign:""
      };
      state.search = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLeadData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action?.payload?.data;
        state.pagination = action?.payload?.pagination;
        state.error = "";
      })
      .addCase(fetchLeadData.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.error.message;
      });
  },
});

export const { 
  setSearch, 
  setSort, 
  setPage, 
  setLimit, 
  setFilter,
  resetFilters 
} = leadSlice.actions;


export const selectLeadFilters = (state) => ({
  ...state.leadData.filters,
  search: state.leadData.search,
  sortField: state.leadData.sortField,
  sortOrder: state.leadData.sortOrder,
  page: state.leadData.pagination.page,
  limit: state.leadData.pagination.limit
});

export default leadSlice.reducer;