import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignData } from "../redux/slices/campaignSlice";
const { Select } = require("@chakra-ui/react");

const CampaignSelectDD = ({ props }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const result = await dispatch(fetchCampaignData());
    if (result.payload.status === 200) {
      setData(result?.payload?.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const campaigns = data || [];

  return (
    <Select
      disabled={props.disabled}
      onChange={(e) => props.setCampaign(props.name, e.target.value)}
      value={props.value}
      style={{ fontSize: "14px" }}
    >
      <option value="">-</option>
      {campaigns?.map((item) => {
        return (
          <option key={item._id} value={item._id}>
            {item.name}
          </option>
        );
      })}
    </Select>
  );
};

export default CampaignSelectDD;
